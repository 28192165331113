import { Switch, Route } from 'react-router-dom/cjs/react-router-dom';

import logo from './logo-light.jpg';
import './App.css';

const DM_DESCRIPTION = "Simple, but Deeper!"

function App()
{
  return (
    <div className="App">
      <header className="App-header">
        <img className="App-logo" src={logo} />
        <h2>{DM_DESCRIPTION}</h2>
        <button>Dashboard</button>
      </header>


      <div>

        <Switch>
          <Route path="/" exact>
            <title>Deep Monitor - {DM_DESCRIPTION}</title>
            <p>Nothing to show yet ...</p>
          </Route>

          <Route path="*">
            <title>Deep Monitor - 404 Not Found</title>
            <h3>404 - Not Found</h3>
            <a href='/'>BACK TO HOME</a>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
